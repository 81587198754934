import React from 'react';
import { PauseTypography } from '../PauseTypography';
import { ChevronDown } from '../../../assets/icons/feather/components';

export const PauseCustomSelect = (props: {
  disabled?: boolean,
  fullWidth?: boolean,
  label: string,
  onClick: () => any,
}) => {
  const {
    disabled = false,
    fullWidth = false,
    label,
    onClick = () => null,
  } = props;
  return (
    <div
      css={(t) => ({
        transition: 'all 0.2s ease-in-out',
        display: 'flex',
        maxWidth: fullWidth ? '100%' : '10rem',
        padding: t.spacing(1.25, 1.25, 1.25, 2),
        borderRadius: t.spacing(1),
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: t.spacing(2),
        verticalAlign: 'baseline',
        border: `2px solid ${t.get('palette.pause.background.dark')}`,
        cursor: !disabled ? 'pointer' : 'default',
        '&:hover': {
          border: `2px solid ${t.get('palette.pause.primary.light')}`,
        },
      })}
      onClick={onClick}
    >
      <PauseTypography
        dontWrapText
      >
        {label}
      </PauseTypography>
      <ChevronDown css={{
        width: '20px',
        height: '20px',
      }}
      />
    </div>
  );
};
