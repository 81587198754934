import React from 'react';
import { PauseTypography } from '../components/PauseTypography';
import { PauseVerticalTab, PauseVerticalTabsList } from '../components/PauseVerticalTabs';
import { useMe } from '../api/me';

const Count = ({ value }: { value: number }) => <PauseTypography variant="body2">{value}</PauseTypography>;

export const MembersMainVerticalNav = ({
  totalMembers = 0,
  totalTeams = 0,
  totalArchivedMembers = 0,
  loading = false,
}: {
  totalMembers? :number,
  totalTeams? :number,
  totalArchivedMembers? :number,
  loading?: boolean
}) => {
  const { me } = useMe();
  return (
    <PauseVerticalTabsList>
      <PauseVerticalTab
        {...{
          value: 'members',
          label: 'All members',
          endIcon: loading ? <></> : <Count value={totalMembers} />,
        }}
      />
      <PauseVerticalTab
        {...{
          value: 'teams',
          label: 'Teams',
          endIcon: loading ? <></> : <Count value={totalTeams} />,
        }}
      />
      {me?.isAdmin && (
      <PauseVerticalTab
        {...{
          value: 'archived-members',
          label: 'Archived members',
          endIcon: loading ? <></> : <Count value={totalArchivedMembers} />,
        }}
      />
      )}

    </PauseVerticalTabsList>
  );
};
