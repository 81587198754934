import React, { createRef, useEffect, useMemo, useState } from 'react';
import { Fade, Popover } from '@material-ui/core';
import Sticky from 'react-sticky-el';
import { PauseMenuItem } from '../PauseMenuItem';
import { PauseInput } from '../PauseInput';
import { Search } from '../../../assets/icons/feather/components';
import { PauseCheckbox } from '../PauseCheckbox';
import { PauseRadio } from '../PauseRadio';
import { PauseTypography } from '../PauseTypography';

type BaseProps = {
  children: React.ReactNode;
  id: string;
  isOpen: boolean;
  onClose: (any?) => any;
  autoWidth: boolean;
  keepMounted?: boolean;
  disableScrollLock?: boolean;
  options: Option[];
  searchLabel?: string;
  loading?: boolean;
  readOnly?: boolean;
  hideSearch?: boolean;
};

type MultiSelectProps = BaseProps & {
  multiple: true;
  selected: string[];
  onUpdateSelection: (a: string[]) => any;
};

type SingleSelectProps = BaseProps & {
  multiple?: false;
  selected: string | null;
  onUpdateSelection: (a: string) => any;
};

type Option = {
  label: string;
  value: string;
  description?: string;
};

const toggle = ({ currentSelection, toToggle }: { currentSelection: string[]; toToggle: string }) => {
  if (!toToggle) return currentSelection;
  if (currentSelection.includes(toToggle)) return currentSelection.filter((s) => s !== toToggle);
  return currentSelection.concat(toToggle);
};

export const PauseCustomDropdown = (props: SingleSelectProps | MultiSelectProps) => {
  const {
    children,
    id,
    isOpen,
    onClose = () => {},
    options = [],
    keepMounted,
    disableScrollLock = false,
    selected,
    onUpdateSelection = () => null,
    searchLabel,
    loading = false,
    readOnly = false,
    hideSearch = false,
  } = props as SingleSelectProps;

  const {
    multiple,
    selected: selectedMultiple,
    onUpdateSelection: onUpdateSelectionMultiple,
  } = props as MultiSelectProps;

  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  // const [popoverWidth, setPopoverWidth] = React.useState<string | number | null>(400);
  const anchorRef = createRef<any>();

  useEffect(() => {
    if (anchorRef && !anchorEl) {
      setAnchorEl(anchorRef.current);
    }
  }, [anchorRef]);

  useEffect(() => {
    if (anchorRef) {
      // setPopoverWidth(anchorRef.current.clientWidth);
    }
  }, [anchorRef]);

  const [searchText, setSearchText] = useState<string>('');
  const matchText = (searchKey) => (option) => {
    if (typeof option.label === 'string') {
      if (option.label.toLowerCase().indexOf(searchKey.toLowerCase()) > -1) return true;
    }
    if (option.value.toLowerCase().indexOf(searchKey.toLowerCase()) > -1) return true;
    return false;
  };
  const filteredList = useMemo(() => options.filter(matchText(searchText)), [options, searchText]);
  const clearSearch = () => setSearchText('');

  const handleClose = () => {
    clearSearch();
    onClose();
  };

  return (
    <div>
      {children && (
        <div css={{ position: 'relative' }} ref={anchorRef} aria-describedby={id} aria-haspopup="true" id={id}>
          {children}
        </div>
      )}
      <Popover
        id={id}
        {...(disableScrollLock && {
          container: anchorEl?.parentNode,
        })}
        anchorEl={anchorEl}
        open={anchorEl && isOpen}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 0,
        }}
        keepMounted={keepMounted}
        transformOrigin={{
          vertical: -4,
          horizontal: 0,
        }}
        css={(theme) => ({
          '.MuiPopover-paper': {
            width: 'auto',
            boxShadow: theme.pauseShadows['16'],
            borderRadius: theme.spacing(1.5),
            background: theme.get('palette.pause.white.main'),
            paddingBottom: hideSearch ? undefined : theme.spacing(2),
          },
        })}
      >
        <div
          id="sticky-parent-wrapper"
          css={(t) => ({
            width: '300px',
            maxHeight: t.spacing(50),
            overflowY: 'auto',
          })}
        >
          {!hideSearch && (
            <Sticky
              scrollElement="#sticky-parent-wrapper"
              topOffset={0}
              dontUpdateHolderHeightWhenSticky
              stickyStyle={{
                // top: '0px',
                // paddingTop: 0,
                // paddingBottom: 0,
                zIndex: 200,
                width: '285px',
              }}
            >
              <div
                css={(t) => ({
                  padding: t.spacing(2),
                  background: t.get('palette.pause.white.main'),
                })}
              >
                <PauseInput
                  /* @ts-ignore */
                  leftIcon={<Search />}
                  placeholder={searchLabel || 'Search'}
                  value={searchText}
                  onChange={(e) => setSearchText(e?.target?.value || '')}
                />
              </div>
            </Sticky>
          )}
          <>
            {filteredList.length ? (
              filteredList.map((option) => (
                <PauseMenuItem key={option.value}>
                  <div
                    css={(t) => ({
                      padding: t.spacing(0.5, 0),
                    })}
                  >
                    {multiple ? (
                      <PauseCheckbox
                        label={option.label}
                        checked={selectedMultiple.includes(option.value)}
                        description={option.description}
                        onChange={() =>
                          !loading && !readOnly
                            ? onUpdateSelectionMultiple(
                                toggle({
                                  currentSelection: selectedMultiple,
                                  toToggle: option.value,
                                }),
                              )
                            : null
                        }
                        disabled={loading || readOnly}
                      />
                    ) : (
                      <PauseRadio
                        checked={selected === option.value}
                        label={option.label}
                        description={option.description}
                        onChange={() => (!loading && !readOnly ? onUpdateSelection(option.value) : null)}
                        disabled={loading || readOnly}
                      />
                    )}
                  </div>
                </PauseMenuItem>
              ))
            ) : (
              <PauseMenuItem
                css={() => ({
                  cursor: 'not-allowed',
                })}
              >
                <PauseTypography
                  css={(t) => ({
                    color: t.get('palette.pause.primaryLight.dark'),
                  })}
                >
                  No options
                </PauseTypography>
              </PauseMenuItem>
            )}
          </>
        </div>
      </Popover>
    </div>
  );
};
