import React, { ReactNode, useMemo } from 'react';
import Link from 'next/link';
import { motion } from 'framer-motion';
import { PauseTypography } from '../PauseTypography';
import { PauseTextButton } from '../PauseTextButton';
import { ArrowLeft, ArrowRight } from '../../../assets/icons/feather/components';

const IntercomHelpMessage = () => (
  <div css={(theme) => ({
    textAlign: 'center',
    display: 'grid',
    alignItems: 'flex-end',
    margin: theme.spacing(2, 0),
  })}
  >
    {/* @ts-ignore */}
    <PauseTextButton
      inline
      onClick={() => window && (window as any).Intercom && (window as any).Intercom('show')}
    >
      <PauseTypography
        variant="subtitle"
        css={{
          display: 'flex',
          gap: 10,
          alignItems: 'center',
        }}
      >
        Have a doubt? Chat with us
        {' '}
        <ArrowRight />
      </PauseTypography>
    </PauseTextButton>
  </div>
);

export interface PauseOnboardingProps {
  children: ReactNode,
  title: string | ReactNode,
  titleIcon?: ReactNode,
  topIcon?: ReactNode,
  showBackButton?: boolean,
  backButtonHref?: string|{[x:string]: any},
  maxWidth?: string,
  backgroundImage?: ReactNode,
  backgroundImagePosition?: 'left'|'right',
  showStepper?: boolean,
  step: {
    current: number,
    total: number
  },
  size?: 'default' | 'small'
}

const TOP_OFFSET = 8;
const SIDE_OFFSET = 8;

export const PauseOnboardingPage = (
  {
    children,
    title,
    titleIcon,
    topIcon,
    showBackButton = true,
    backButtonHref = '/',
    maxWidth,
    // backgroundImage,
    // backgroundImagePosition = 'left',
    step = { current: 1, total: 3 },
    showStepper = true,
    size = 'default',
  }: PauseOnboardingProps,
) => {
  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  const computedMaxWidth = useMemo(() => maxWidth || '500px', [maxWidth]);
  const variant = {
    default: 'display',
    small: 'header',
  }[size];
  return (
    <motion.div initial="hidden" animate="visible" variants={variants}>
      <div
        css={(theme) => ({
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          background: theme.get('palette.pause.background.light'),
          display: 'grid',
          alignItems: 'stretch',
          justifyContent: 'center',
          justifyItems: 'center',
          padding: theme.spacing(TOP_OFFSET, 0, 0),
          overflow: 'scroll',
        })}
      >
        <div
          css={(theme) => ({
            display: 'grid',
            gridGap: theme.spacing(0),
            gridAutoFlow: 'row',
            gridTemplateRows: 'auto',
            alignItems: 'flex-start',
          })}
        >
          {showBackButton && (
            <div
              css={(theme) => ({
                position: 'absolute',
                top: theme.spacing(TOP_OFFSET),
                left: theme.spacing(SIDE_OFFSET),
              })}
            >
              <Link href={backButtonHref}>
                {/* @ts-ignore */}
                <PauseTextButton compact size="large" startIcon={<ArrowLeft />}>
                  Back
                </PauseTextButton>
              </Link>
            </div>
          )}
          {topIcon && (
            <div
              css={(theme) => ({
                height: theme.spacing(14),
                margin: 'auto',
                marginBottom: theme.spacing(2),
                svg: {
                  height: '100%',
                },
              })}
            >
              {topIcon}
            </div>
          )}
          <div
            css={(theme) => ({
              zIndex: 1,
              maxWidth: `calc(100vw - 8*${theme.spacing(SIDE_OFFSET)}px)`,
              position: 'relative',
              display: 'grid',
              alignItems: 'start',
              justifyContent: 'center',
              justifyItems: 'center',
              gridGap: theme.spacing(4),
              // textAlign: 'center',
              paddingBottom: theme.spacing(TOP_OFFSET),
            })}
          >
            <PauseTypography
              variant={variant}
              css={{
                textAlign: 'center',
              }}
            >
              {title}
              {' '}
              {titleIcon && titleIcon}
            </PauseTypography>
            {showStepper && (
              <div>
                <PauseTypography variant="title" color="pause.primary.medium">
                  Step
                  {' '}
                  {step.current.toString()}
                  {' '}
                  of
                  {' '}
                  {step.total.toString()}
                </PauseTypography>
              </div>
            )}
            <div
              css={() => ({
                width: computedMaxWidth,
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              })}
            >
              {children}
            </div>
          </div>
        </div>
        <IntercomHelpMessage />
      </div>
    </motion.div>
  );
};
