import React, { useContext, useState } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { TabPanel, TabContext } from '@material-ui/lab';
import { get } from 'lodash';
import { PauseTypography } from '../PauseTypography';

export const useTabs = ({ initActiveTab }: {
  initActiveTab: string
}) => {
  const [activeTab, setActiveTab] = useState<string>(initActiveTab);
  const changeTab = (newTab: string) => {
    setActiveTab(newTab);
  };
  return { value: activeTab, onChange: changeTab };
};

const PauseVerticalTabsContext = React.createContext<any>({
  value: null,
  onChange: () => null,
});

export const PauseVerticalTabs = ({ value, onChange, children }: {
  value: string,
  onChange: (any?) => any,
  children: React.ReactNode,
}) => (
  <PauseVerticalTabsContext.Provider value={{ value, onChange }}>
    <TabContext value={value}>
      {children}
    </TabContext>
  </PauseVerticalTabsContext.Provider>
);

export const PauseVerticalTabsList = (props: any) => {
  const { value, onChange } = useContext(PauseVerticalTabsContext);
  const css = (theme) => ({
    '.MuiTabs-flexContainerVertical ~ .MuiTabs-indicator': {
      height: theme.spacing(0.5),
      display: 'none',
      background: get(theme, 'palette.pause.primary.dark'),
    },
  });
  return (
    <Tabs
      css={css}
      orientation="vertical"
      {...props}
      value={value}
      onChange={(_, v) => onChange(v)}
    />
  );
};

export const PauseVerticalTab = ({
  label, startIcon, endIcon, ...rest
}: {
  label: string,
  startIcon?: any,
  endIcon?: any,
  [key: string]: any
}) => {
  const LabelComponent = (
    <div
      css={(theme) => ({
        display: 'grid',
        gridGap: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'space-between',
        gridAutoFlow: 'column',
        width: '100%',
      })}
    >
      <div css={(theme) => ({
        display: 'grid',
        gridGap: theme.spacing(2),
        alignItems: 'center',
        gridAutoFlow: 'column',
        // justifyContent: 'space-between',
        width: '100%',
      })}
      >
        {startIcon && (
        <div css={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          fontSize: theme.spacing(2.5),
        })}
        >
          {startIcon}
        </div>
        )}
        <PauseTypography
          css={() => ({
            textAlign: 'left',
          })}
          variant="button"
        >
          {label}
        </PauseTypography>
      </div>

      {endIcon && (
      <div css={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        fontSize: theme.spacing(2.5),
      })}
      >
        {endIcon}
      </div>
      )}
    </div>
  );
  return (
    <Tab
      css={(theme) => ({
        textTransform: 'unset',
        '&.MuiTab-root': {
          width: '100%',
          minWidth: 'auto !important',
          paddingRight: theme.spacing(2),
          paddingLeft: theme.spacing(2),
          paddingBottom: theme.spacing(2),
          paddingTop: theme.spacing(2),
          color: get(theme, 'palette.pause.primary.medium'),
          transition: 'all 0.2s ease-in-out',
          '&.Mui-selected': {

            color: get(theme, 'palette.pause.primary.dark'),
            backgroundColor: get(theme, 'palette.pause.background.medium'),
          },
          '&.Mui-disabled': {
            opacity: 0.5,
            cursor: 'not-allowed',
          },
          '&:hover': {
            color: get(theme, 'palette.pause.primary.dark'),
            backgroundColor: get(theme, 'palette.pause.background.medium'),

          },
        },
        '&.MuiTab-textColorInherit': {
          opacity: 1,
        },
      })}
      disableFocusRipple
      disableRipple
      label={LabelComponent}
      {...rest}
    />
  );
};

export const PauseVerticalTabPanel = ({ value, children = <></> }: {
  value:string,
  children?: React.ReactNode,
}) => (
  <TabPanel value={value} css={() => ({ padding: 0 })}>
    {children}
  </TabPanel>
);
