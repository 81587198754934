import React from 'react';
import Head from 'next/head';
import { useOnboardingData } from '../../src/api/onboarding2';
import {
  getOnboardingStepFromRoute,
  getRouteFromOnboardingStep,
  isAllowedToViewThisScreen,
} from '../../utils/onboarding2';
import { useEnhancedRouter } from '../../router';
import { OnboardingStep } from '../../src/types/onboarding';
import { PauseLoader } from '../../src/components/PauseLoader';
import { AppStateEnum, useAppState } from '../../src/api/app-state';

export const Card = ({ children, ...rest }: {children: any}) => (
  <div
    css={(theme) => ({
      padding: theme.spacing(3),
      background: 'white',
      borderRadius: theme.spacing(1),
      boxShadow: theme.pauseShadows[2],
    })}
    {...rest}
  >
    {children}
  </div>
);

export const OnboardingStepCheck = ({ children }) => {
  const { checkpointData, loading } = useOnboardingData();
  const router = useEnhancedRouter();
  const stepFromRoute = getOnboardingStepFromRoute(router.pathname);
  const [checking, setChecking] = React.useState(true);
  React.useEffect(() => {
    if (loading || !checkpointData) return;

    if (!isAllowedToViewThisScreen(checkpointData)(stepFromRoute)) {
      router.push({
        pathname: getRouteFromOnboardingStep(checkpointData?.step || OnboardingStep.OrgExistsCheck),
        query: router.query,
      });
      return;
    }
    setChecking(false);

    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkpointData, loading]);
  if (checking) return <PauseLoader fullPage />;
  return (
    <>
      <Head>
        <title>Welcome | Pause</title>
      </Head>
      {children}
    </>
  );
};

export default function Page() {
  const { appState } = useAppState();
  const { checkpointData, loading } = useOnboardingData({
    enabled: appState === AppStateEnum.createOrg,
  });
  const router = useEnhancedRouter();
  React.useEffect(() => {
    if (appState !== AppStateEnum.createOrg) {
      router.push({
        pathname: getRouteFromOnboardingStep(OnboardingStep.Done),
        query: router.query,
      });
      return;
    }
    if (loading || !checkpointData) return;
    const route = getRouteFromOnboardingStep(checkpointData?.step || OnboardingStep.OrgExistsCheck);
    router.push({ pathname: route, query: router.query });
  }, [checkpointData, loading]);
  return <PauseLoader fullPage />;
}
