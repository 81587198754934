import * as React from 'react';

const SvgOnbIllus2 = (props) => (
  <svg viewBox="0 0 444 586" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#onb-illus-2_svg__a)">
      <path
        d="M167.876 138.377s-61.986 45.638-58.24 152.128c3.745 106.49 5.064 98.249 5.064 98.249h220.246s31.652-177.482-60.772-251.011c0 0-15.826 21.552-52.542 21.552-36.717 0-53.756-20.918-53.756-20.918Z"
        fill="#B6DBAC"
      />
      <path
        d="M133.216 389.812s-14.243 35.18 25.164 39.458l16.617 43.262 40.357-1.426s3.798-60.376-4.273-70.36l-44.63-.475-15.193-13.311M234.345 400.748s-11.395 27.573-3.798 69.409l39.881.475 18.042-42.258s29.015 3.328 24.689-38.983h-21.365l-14.244 10.935"
        fill="#F19C9E"
      />
      <path
        d="m292.413 530.691-.145-11.41-2.216.317c-.475-.092-.897.225-1.319.37a26.095 26.095 0 0 0-3.719 1.321c-2.202 1.149-4.128 2.72-6.409 3.724-2.282 1.003-4.643 2.429-7.28 2.852a27.893 27.893 0 0 1-4.326.568h-5.737a25.38 25.38 0 0 1-9.126-2.522c-2.203-.964-4.392-1.955-6.595-2.919a54.997 54.997 0 0 0-7.913-2.799 15.683 15.683 0 0 0-6.277 0 56.714 56.714 0 0 0-8.929 1.505c-2.136.647-4.312 1.096-6.462 1.743-4.511 1.321-8.929 3.024-13.558 3.962a30.794 30.794 0 0 1-8.388.726 48.208 48.208 0 0 1-7.095-1.241c-.95-.198-1.807-.647-2.756-.885-.95-.238-1.873-.383-2.796-.7-1.477-.515-2.981-.924-4.458-1.466-2.638-.964-5.275-1.664-7.913-2.641-1.82-.686-3.653-1.413-5.526-1.941a12.301 12.301 0 0 0-3.548-.291c-3.31 0-6.224 2.259-9.047 3.804l-.633 71.31h2.532v1.215H293.31v-64.576l-.897-.026Z"
        fill="#9DC7D1"
      />
      <path
        d="m262.517 546.537-4.748-4.833-.871 8.716 5.619-3.883ZM263.623 546.142s12.344-7.051 14.507 1.321c0 0-1.741 9.35-14.006-.159M178.717 574.346l4.748-4.833.87 8.715-5.618-3.882ZM177.609 573.951s-12.345-7.051-14.507 1.321c0 0 1.74 9.349 14.006-.159"
        fill="#F05625"
      />
      <path
        d="m174.166 471.089 41.781-.265M228.238 471.089l41.781-.265M150.109 505.371l.317 90.327 141.801.316.633-91.593M228.291 495.232l-12.898-.316M218.24 439.769h10.208M181.209 494.915H1.979v-55.146H161.98M1.979 494.916l.316 84.938h12.344l14.877-83.988M20.969 438.819v-16.164a32.358 32.358 0 0 1 9.47-22.873 32.257 32.257 0 0 1 22.855-9.454l60.771.317M262.791 495.549h179.23v-55.146l-158.26-.159"
        stroke="#3D4298"
        strokeWidth={3.478}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m442.022 495.545-.317 84.938h-12.344l-14.877-83.987M423.032 439.461v-16.177a32.35 32.35 0 0 0-9.456-22.858 32.264 32.264 0 0 0-22.829-9.469l-60.772.317"
        stroke="#3D4298"
        strokeWidth={3.478}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M225.614 145.894c-1.952.211-3.957.37-5.856.489 1.688 1.545 2.11 3.961 3.548 5.705.738-1.638 1.503-3.54 2.07-5.283M265.917 88.645c-2.018-17.524-9.153-56.784-32.971-65.46a14.27 14.27 0 0 0-4.942-20.431 14.23 14.23 0 0 0-14.932.716 14.281 14.281 0 0 0-2.968 20.811c-22.288 10.089-29.094 47.632-31.046 64.707a5.529 5.529 0 0 0 1.997 4.92 5.516 5.516 0 0 0 5.217.957 5.477 5.477 0 0 0 3.469-3.394l11.724-33.186a5.494 5.494 0 0 1 6.687-3.486 35.05 35.05 0 0 0 7.913 1.32 5.5 5.5 0 0 0 .936 0c4.801.318 12.332.463 17.607-1.043a29.678 29.678 0 0 0 2.202-.554 5.494 5.494 0 0 1 4.034.415 5.506 5.506 0 0 1 2.653 3.071l11.724 33.185a5.52 5.52 0 0 0 2.543 3.033 5.505 5.505 0 0 0 7.191-1.671 5.523 5.523 0 0 0 .949-3.844l.013-.066Z"
        fill="#939393"
      />
      <path
        d="M177.542 109.841a8.074 8.074 0 0 1-5.63-2.554 8.101 8.101 0 0 1-2.177-5.792 8.074 8.074 0 0 1 2.55-5.638 8.08 8.08 0 0 1 5.785-2.18M269.201 92.464a8.061 8.061 0 0 1 6.035 1.57 8.086 8.086 0 0 1-3.991 14.461"
        stroke="#3D4398"
        strokeWidth={2.319}
        strokeMiterlimit={10}
      />
      <path
        d="m114.739 389.279 219.587.106c5.961-39.432 23.62-196.842-63.305-255.171-.408-43.579-8.796-113.568-51.434-112.67-41.517.845-43.06 77.2-41.9 109.606-100.509 67.348-62.961 258.076-62.961 258.076"
        stroke="#3D4298"
        strokeWidth={3.478}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m167.439 138.839 18.253 10.485a71.801 71.801 0 0 0 72.418-.489l16.867-9.996M155.229 428.901c-26.706-1.069-25.902-26.741-23.831-39.511M154.754 416.844l45.104 127.407 10.92.475s15.496-126.35-5.012-159.153M312.987 388.874c2.137 12.585 3.324 38.93-23.739 40.013M289.22 424.078l-44.616 120.17-10.92.476s-15.628-127.394 5.276-159.563M205.898 582.281c4.065 0 7.359-7.024 7.359-15.688 0-8.664-3.294-15.688-7.359-15.688-4.064 0-7.359 7.024-7.359 15.688 0 8.664 3.295 15.688 7.359 15.688ZM237.549 582.281c4.064 0 7.359-7.024 7.359-15.688 0-8.664-3.295-15.688-7.359-15.688-4.065 0-7.36 7.024-7.36 15.688 0 8.664 3.295 15.688 7.36 15.688ZM326.294 210.623s66.47 84.621-32.285 172.095c0 0-9.97-8.082-14.243 7.606M122.244 210.623s-66.47 84.621 32.285 172.095c0 0 9.971-8.082 14.244 7.606"
        stroke="#3D4298"
        strokeWidth={3.478}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M215.313 108.044a4.197 4.197 0 0 0 4.194-4.199c0-2.319-1.878-4.2-4.194-4.2a4.197 4.197 0 0 0-4.194 4.2 4.197 4.197 0 0 0 4.194 4.199ZM229.821 108.044a4.197 4.197 0 0 0 4.194-4.199c0-2.319-1.878-4.2-4.194-4.2a4.197 4.197 0 0 0-4.194 4.2 4.197 4.197 0 0 0 4.194 4.199Z"
        fill="#3D4298"
      />
      <path
        d="M216.896 125.525s-1.319 5.547 4.906 6.181c6.225.633 5.856-6.181 5.856-6.181"
        stroke="#3D4298"
        strokeWidth={2.319}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M278.355 308.821H167.572v91.911h110.783v-91.911Z"
        fill="#DDD"
        stroke="#3D4298"
        strokeWidth={3.478}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="m166.49 400.784-15.193-11.172 15.43.238" fill="#CFCFCE" />
      <path
        d="m166.49 400.784-15.193-11.172 15.43.238"
        stroke="#3D4298"
        strokeWidth={3.478}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="m279.013 389.615 12.819-.238-13.478 11.357" fill="#CFCFCE" />
      <path
        d="m279.013 389.615 12.819-.238-13.478 11.357"
        stroke="#3D4298"
        strokeWidth={3.478}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M184.69 578.876a22.196 22.196 0 0 1-3.692-2.641 29.65 29.65 0 0 0-9.311-4.543 8.625 8.625 0 0 0-5.592 0c-2.757 1.215-4.313 5.388-1.425 7.316 2.889 1.928 7.465-.238 10.221-1.849 3.561-2.06 5.962-4.913 8.639-7.923.448 3.182 1.53 6.418 1.16 9.64ZM256.737 550.827a22.1 22.1 0 0 0 3.68-2.641 29.705 29.705 0 0 1 9.324-4.543 8.625 8.625 0 0 1 5.592 0c2.743 1.215 4.299 5.388 1.424 7.316-3.086 2.06-7.477-.238-10.234-1.849-3.548-2.06-5.961-4.912-8.638-7.923-.436 3.182-1.491 6.418-1.148 9.64Z"
        stroke="#3D4298"
        strokeWidth={1.159}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M201 133.15a31.94 31.94 0 0 0 20.139 7.839 31.964 31.964 0 0 0 20.531-6.763L246 131"
        stroke="#3D4298"
        strokeWidth={2.319}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M217.729 115.505s5.275-3.803 9.495 0"
        stroke="#3C4398"
        strokeWidth={2.319}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="onb-illus-2_svg__a">
        <path fill="#fff" transform="translate(0 .977)" d="M0 0h444v597.023H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgOnbIllus2;
